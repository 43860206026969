import React, { useState } from 'react';
import cloneDeep from "lodash/cloneDeep";

import { Classes, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { FolderNode } from '../dataObjects/FolderNode';
import * as MdoApi from '../workers/MdoApi';
import { IconNames } from '@blueprintjs/icons';

type NodePath = number[];

type TreeNodeMap = Map<string, TreeNodeInfo>;

interface Props {
    rootFolder: FolderNode;
    onFolderPathSelected: (folderPath: FolderNode[]) => void;
    baseServerURL: string;
}

export function LeftHandNavComponent(props: Props) {
    let rootFolder = props.rootFolder;
    let rootFolderTreeNode: TreeNodeInfo = { 
        id: rootFolder.id,
        label: rootFolder.name,
        icon: IconNames.FolderClose,
        hasCaret: true,
        isExpanded: false,
    };
    let defaultMap = new Map<string, TreeNodeInfo>();
    defaultMap.set(rootFolder.id, rootFolderTreeNode);
    const [treeNodeMap, setTreeNodeMap] = useState<TreeNodeMap>(defaultMap);

    const getNodesFromServer = (copiedNode: TreeNodeInfo, newTreeNodeMap: Map<string, TreeNodeInfo>) => {
        console.log("Getting nodes from server...");
        let nodeId = String(copiedNode.id);
        MdoApi.getChildrenFoldersFromServer(nodeId)
        .then(returnedChildren => {
            console.log("... retrieved " + returnedChildren.length + " child(ren).");
            copiedNode.childNodes = returnedChildren.map(child => ({
                id: child.id,
                label: child.name,
                icon: IconNames.FolderClose,
                hasCaret: true,
            }));
            console.log("Saving modified treeNodeMap.");
            setTreeNodeMap(newTreeNodeMap);
        }).catch(ex => {
            console.log("Failed to get the child folders for left hand nav.");
        })
    }

    const getCopiedMapAndNode = (node: TreeNodeInfo, nodeIndexPath: NodePath): [Map<string, TreeNodeInfo>, TreeNodeInfo] => {
        let newTreeNodeMap: Map<string, TreeNodeInfo> = cloneDeep(treeNodeMap);
        let copiedNode: TreeNodeInfo = newTreeNodeMap.get(rootFolder.id) || { id: '', label: '' };
        nodeIndexPath.slice(1).forEach((nodeIndex) => {
            copiedNode = (copiedNode.childNodes||[])[nodeIndex] || { id: '', label: '' };
        });
        return [newTreeNodeMap, copiedNode];
    }

    const handleNodeExpand = (node: TreeNodeInfo, nodeIndexPath: NodePath) => {
        console.log(node.label + " expanded")
        console.log(node);
        const[newTreeNodeMap, copiedNode] = getCopiedMapAndNode(node, nodeIndexPath);
        copiedNode.isExpanded = true;
        copiedNode.icon = IconNames.FolderOpen;
        if (node.childNodes == null) {
            getNodesFromServer(copiedNode, newTreeNodeMap);
        } else {
            setTreeNodeMap(newTreeNodeMap);
        }
    }

    const handleNodeClick = (node: TreeNodeInfo, nodeIndexPath: NodePath) => {
        console.log(node.label + " clicked");
        // console.log(nodeIndexPath);
        let nodePath: FolderNode[] = [{
            id: rootFolder.id,
            name: rootFolder.name,
            editDate: Date.now()
        }];
        let currentNode = treeNodeMap.get(rootFolder.id) as TreeNodeInfo;
        nodeIndexPath.slice(1).forEach((nodeIndex) => {
            console.log("Looking at node " + nodeIndex);
            let childNodes = currentNode.childNodes as TreeNodeInfo[];
            currentNode = childNodes[Number(nodeIndex)];
            nodePath.push({
                id: String(currentNode.id),
                name: String(currentNode.label),
                editDate: Date.now()
                // the editDate for this folder isn't going to matter. If it ends up mattering, store Folders 
                // in a separate state map.
            });
        });
        props.onFolderPathSelected(nodePath);
    }

    const handleNodeCollapse = (node: TreeNodeInfo, nodeIndexPath: NodePath) => {
        console.log(node.label + " collapsed")
        const[newTreeNodeMap, copiedNode] = getCopiedMapAndNode(node, nodeIndexPath);
        copiedNode.isExpanded = false;
        copiedNode.icon = IconNames.FolderClose
        setTreeNodeMap(newTreeNodeMap);
    }

    let rootFolderNode = treeNodeMap.get(rootFolder.id) as TreeNodeInfo;

    let nodes = [rootFolderNode]
    if (nodes === undefined) {
        nodes = [];
    }
    if (nodes.length === 0) {
        return <>(retreiving folder information)</>;
    }
    return (
        <Tree
            contents={nodes}
            onNodeClick={handleNodeClick}
            onNodeCollapse={handleNodeCollapse}
            onNodeExpand={handleNodeExpand}
            className={Classes.ELEVATION_0}
        />
    )
}
