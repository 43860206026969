import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js'
import { Children } from '../dataObjects/Children';
import { DocumentNode } from '../dataObjects/DocumentNode';
import { FolderNode } from '../dataObjects/FolderNode';
import * as MdoApi from '../workers/MdoApi';
import { Button } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { CheckboxState, FileOperation, getUsername } from '../util/util';
import { RepoNode } from '../dataObjects/RepoNode';

// Make this work with documents and folders, with document icons, with file times, with download
// right clicks, with delete, with create/upload, with folder creation, with renaming, etc.

export const useGetChildren = (baseServerURL: string, folder: FolderNode, childrenRefetchRequiredCounter: number, sortOrder: string, clearCheckboxes: any): Children => {
    const [children, setChildren] = useState<Children>({
        childFolders: [],
        childDocuments: []
    });

    Bugsnag.leaveBreadcrumb("MainWindow.tsx: useGetChildren called for folder " + folder.name + " by " + getUsername() + ".");
    
    useEffect(() => {
        // console.log("useEffect called in useGetChildren for folder " + folder.name + " and childrenRefetchRequiredCounter " + childrenRefetchRequiredCounter + " and sortOrder " + sortOrder + ".");
        MdoApi.getChildrenFromServer(folder.id, sortOrder)
            .then(returnedchildren => {
                // console.log("Refetched the children, in useEffect, for the main table.");
                setChildren(returnedchildren);
                clearCheckboxes();
                // Bugsnag.notify("JFW test - Refetched the children, in useEffect, for the main table.");  // a test line to kick out bugsnag notifications
            })
            .catch(ex => {
                console.log("Failed to get the children for the main table.");
                console.log(ex);
                if (ex.response?.status != 401){
                    // the user will be redirected to the logon page if the server returns a 401. No need to deal with that.
                    alert("Server error. Failed to get children for the main table. Please refresh the page.");
                    Bugsnag.notify('baseServerURL = ' + baseServerURL + '. username = ' + getUsername() + '. Attempt to get children for the main table failed. Error: ' + 
                                    ex.message + '. response.status = ' + ex.response?.status + '. Folder: ' + folder.name + '.');
                }
            });
    },
        [folder, baseServerURL, childrenRefetchRequiredCounter, sortOrder]
    );
    return children;
}

interface Props {
    rootFolder: FolderNode,
    parentFolder: FolderNode;
    baseServerURL: string;
    sortOrder: string;
    selectAllCheckboxState: CheckboxState;  // when this state changes to Checked, MainTable should call setCheckedDocuments with all the documents in the table
    setCheckedDocumentsWithReason: (checkedDocuments: Set<DocumentNode>, becauseOfCheckboxClickInMainWindow: boolean) => any;
    onFolderChosen: (folder: FolderNode) => any;
    performOperationOnNodes: (operation: FileOperation, nodes: RepoNode[]) => void;
    childrenRefetchRequiredCounter: number;
    onDocumentChosen: (documentId: string, documentName: string) => void;
    currentUserIsAdmin: boolean;
}

export function MainWindowComponent(props: Props) {
    const [anchorCheckboxIndex, setAnchorCheckboxIndex] = useState(-1);
    const [anchorCheckboxChecked, setAnchorCheckboxChecked] = useState(false);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState<Set<number>>(new Set()); // the set of indexes of the checkboxes that are checked

    const clearCheckboxes = () => {
        // called from useGetChildren when the list of child documents is updated
        setCheckedCheckboxes(new Set());
    }

    const checkboxOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        console.log("At start, checkedCheckboxes set to " + (Array.from(checkedCheckboxes.values()).join(', ')));
        const target = e.currentTarget;
        const index = parseInt(target.value);
        console.log("Checkbox " + index + " (" + target.name + ") checked = " + target.checked + ".");
        let rangeClick = false;
        if (e.nativeEvent instanceof PointerEvent) {
            const pointerEvent = e.nativeEvent as PointerEvent;
            rangeClick = pointerEvent.shiftKey && (anchorCheckboxIndex >= 0);
            console.log("rangeClick set to " + rangeClick + " based on pointerEvent.shiftKey = " + pointerEvent.shiftKey + " and anchorCheckboxIndex = " + anchorCheckboxIndex);
        }
        if (rangeClick) {
            const lesserIndex = Math.min(anchorCheckboxIndex, index);
            const greaterIndex = Math.max(anchorCheckboxIndex, index);
            for (var i = lesserIndex; i <= greaterIndex; i++) {
                if (anchorCheckboxChecked) {
                    checkedCheckboxes.add(i);
                    console.log("Based on rangeClick being set, checkbox " + i + " added to checkedCheckboxes set.");
                } else {
                    checkedCheckboxes.delete(i);
                    console.log("Based on rangeClick being set, checkbox " + i + " removed from checkedCheckboxes set.");
                }
            }
        } else {
            if (target.checked) {
                checkedCheckboxes.add(index);
                console.log("Checkbox " + index + " added to the checkedCheckboxes set.");
            } else {
                checkedCheckboxes.delete(index);
                console.log("Checkbox " + index + " removed from the checkedCheckboxes set.");
            }
            setAnchorCheckboxIndex(index);
            setAnchorCheckboxChecked(target.checked);
            console.log("anchorCheckboxIndex set to " + index + ". anchorCheckboxChecked set to " + target.checked);
        }
        setCheckedCheckboxes(new Set(checkedCheckboxes));
        console.log("At end, checkedCheckboxes set to " + (Array.from(checkedCheckboxes.values()).join(', ')));

        let checkedDocuments = new Set<DocumentNode>();
        checkedCheckboxes.forEach(checkedBox => {
            checkedDocuments.add(children.childDocuments[checkedBox]);
        })
        props.setCheckedDocumentsWithReason(checkedDocuments, true);
    }

    // console.log("Rendering the main table for parent folder "+props.parentFolder.name)
    const children = useGetChildren(props.baseServerURL, props.parentFolder, props.childrenRefetchRequiredCounter, props.sortOrder, clearCheckboxes);

    let selectAllCheckboxState = props.selectAllCheckboxState;
    let setCheckedDocumentsWithReason = props.setCheckedDocumentsWithReason;
    useEffect(() => {
        // if the selectAllCheckboxState has changed, or if the list of child documents has changed...
        if (selectAllCheckboxState === CheckboxState.Checked) {
            // ... and if the master checkbox is checked, then check all the checkboxes and add all the documents to the checkedDocuments array:
            setCheckedDocumentsWithReason(new Set(children.childDocuments), false);
            let s = new Set<number>();
            for (let i = 0; i < children.childDocuments.length; i++) {
                s.add(i);
            }
            setCheckedCheckboxes(s);
        } else if (selectAllCheckboxState === CheckboxState.Unchecked) {
            // ... and if the master checkbox is unchecked, then uncheck all the checkboxes
            setCheckedDocumentsWithReason(new Set(), false);
            setCheckedCheckboxes(new Set());
        }
    }, [selectAllCheckboxState, children.childDocuments]);

    return (
        <>
            <table className='bp5-html-table bp5-html-table-striped bp5-interactive Main-table'>
                <tbody>
                    {children.childFolders.map(child => (
                        <tr key={child.id}>
                            <td></td>
                            <td className='main-table-name-column'>
                                <Button icon={IconNames.FolderClose} className=" bp5-button bp5-minimal Area-button" onClick={() => props.onFolderChosen(child)}>
                                    {child.name}
                                </Button>
                            </td>
                            <td className='main-table-date-column'>
                                {new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(child.editDate)}
                            </td>
                            <td />
                            {props.currentUserIsAdmin && <td>
                                <Button icon={IconNames.Edit} title="Rename Folder" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Rename, [child])} />
                                <Button icon={IconNames.Move} title="Move Folder" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Move, [child])} />
                                <Button icon={IconNames.Trash} title="Delete Folder" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Delete, [child])} />
                                <Button icon={IconNames.GreaterThan} title="Merge Folder Contents" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Merge, [child])} />
                            </td>}
                        </tr>
                    ))}

                    {children.childDocuments.map((child, index) => (
                        <tr key={child.id}>
                            {props.currentUserIsAdmin && <td>
                                <input type="checkbox" className="file-checkbox" checked={checkedCheckboxes.has(index)} id={"checkbox-" + index} name={child.id} value={index} onChange={checkboxOnChange} />
                            </td>}
                            <td className='table-id-column'>
                                <Button icon="document" className=" bp5-button bp5-minimal Area-button" onClick={() => props.onDocumentChosen(child.id, child.name)}>
                                    {child.name}
                                </Button>
                            </td>
                            <td>
                                {new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(child.editDate)}
                            </td>
                            <td className='main-table-size-column'>
                                {'          '}{child.size}
                            </td>
                            {props.currentUserIsAdmin && <td>
                                <Button icon={IconNames.Edit} title="Rename Document" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Rename, [child])} />
                                <Button icon={IconNames.Move} title="Move Document" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Move, [child])} />
                                <Button icon={IconNames.Trash} title="Delete Document" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Delete, [child])} />
                                <Button icon={IconNames.Duplicate} title="Copy Document" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => props.performOperationOnNodes(FileOperation.Copy, [child])} />
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
