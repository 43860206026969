import { jwtDecode } from "jwt-decode";

export enum CheckboxState {
    Unchecked,
    Checked,
    Indeterminate
}

export enum FileOperation {
    Copy,
    Move,
    Delete,
    Rename,
    Merge,
    None
}

export enum CurrentUserIsAdmin {
    Yes,
    No,
    Unknown
}

export const getUsername = () => {
    return window.localStorage.getItem('username') || 'unknown';
}
