import React, { useState } from 'react';
import cloneDeep from "lodash/cloneDeep";

import { Classes, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { FolderNode } from '../dataObjects/FolderNode';
import * as MdoApi from '../workers/MdoApi';
import { IconNames } from '@blueprintjs/icons';

type NodePath = number[];

type TreeNodeMap = Map<string, TreeNodeInfo>;

interface Props {
    rootFolders: FolderNode[];
    onFolderPathSelected: (folderPath: string) => void;
    baseServerURL: string;
}

export function S3BrowseComponent(props: Props) {
    let defaultMap = new Map<string, TreeNodeInfo>();

    props.rootFolders.forEach(rootFolder => {
        let rootFolderTreeNode = {
            id: rootFolder.id,
            label: rootFolder.name,
            icon: IconNames.FolderClose,
            hasCaret: true,
        };
        defaultMap.set(rootFolder.id, rootFolderTreeNode);
    });

    const [treeNodeMap, setTreeNodeMap] = useState<TreeNodeMap>(defaultMap);

    const getNodesFromServer = (node: TreeNodeInfo) => {
        console.log("Getting nodes from server for node.id " + node.id + "...");
        MdoApi.getChildS3FoldersFromServer(node.id as string)
        .then(returnedChildren => {
            console.log("... retrieved " + returnedChildren.length + " child(ren).");
            node.childNodes = returnedChildren.map(child => ({
                id: child,
                label: child.split('/')[child.split('/').length-2],
                icon: IconNames.FolderClose,
                hasCaret: true,
            }));
            console.log("Saving modified treeNodeMap.");
            setTreeNodeMap(cloneDeep(treeNodeMap));
        }).catch(ex => {
            let msg = "Error getting child nodes from server: " + ex.message;
            console.log(msg);
            alert(msg);
        })
    }

    const handleNodeExpand = (node: TreeNodeInfo, nodePath: NodePath) => {
        console.log(node.label + " expanded")
        node.isExpanded = true;
        node.icon = IconNames.FolderOpen;
        setTreeNodeMap(cloneDeep(treeNodeMap));
        if (node.childNodes == null) {
            getNodesFromServer(node);
        }
    }

    const handleNodeClick = (node: TreeNodeInfo, nodeIndexPath: NodePath) => {
        console.log(node.label + " clicked");
        // console.log(nodeIndexPath);
        // let nodePath: FolderNode[] = [{
        //     id: rootFolder.id,
        //     name: rootFolder.name,
        //     editDate: Date.now()
        // }];
        // let currentNode = treeNodeMap.get(rootFolder.id) as TreeNodeInfo;
        // nodeIndexPath.slice(1).forEach((nodeIndex) => {
        //     console.log("Looking at node " + nodeIndex);
        //     let childNodes = currentNode.childNodes as TreeNodeInfo[];
        //     currentNode = childNodes[nodeIndex];
        //     nodePath.push({
        //         id: String(currentNode.id),
        //         name: String(currentNode.label),
        //         editDate: Date.now()
        //         // the editDate for this folder isn't going to matter. If it ends up mattering, store Folders 
        //         // in a separate state map.
        //     });
        // });
        props.onFolderPathSelected(node.id as string);
    }

    const handleNodeCollapse = (node: TreeNodeInfo, nodePath: NodePath) => {
        console.log(node.label + " collapsed")
        node.isExpanded = false;
        node.icon = IconNames.FolderClose
        setTreeNodeMap(cloneDeep(treeNodeMap));
    }

    let nodes = Array.from(treeNodeMap.values());
    if (nodes.length === 0) {
        return <>(retreiving folder information)</>;
    }
    return (
        <Tree
            contents={nodes}
            onNodeClick={handleNodeClick}
            onNodeCollapse={handleNodeCollapse}
            onNodeExpand={handleNodeExpand}
            className={Classes.ELEVATION_0}
        />
    )
}
