import React, { useRef, useState } from 'react';
import './css/App.css';
import { FolderNode } from './dataObjects/FolderNode';
import { BreadcrumbComponent } from './components/Breadcrumb';
import { MainWindowComponent } from './components/MainWindow';
import { LeftHandNavComponent } from './components/LeftHandNav';
import { SearchBarComponent } from './components/SearchBar';
import { FolderToolbarComponent } from './components/FolderToolbar';
// import { copyOrMoveNodes, 
//     mergeFolders,
//     createFolderOnServer, 
//     deleteNodes, 
//     renameNodeInRepo, 
//     getChildrenFoldersFromServer, 
//     importS3Documents,
//     apiSsearch,
//     getDocumentURL,
//     getCurrentUserIsAdmin,
//     SearchNode,
//     getSelfScanningCredentials,
//     getBackgroundActivityInfo,
//     reindexOpenSearchPathsForDescendants,
//     ScanningCredential, 
//     getUsers,
//     resetUserHardcodedPassword,
//     getFilesToCombine,
//     combineFiles,
//     Node,
//     createCognitoUserSheetApi,
//     createBillingReportApi,
//     setHttpOnlyCookies,
//     noopToProveCommunications} from './workers/MdoApi';
import * as MdoApi from './workers/MdoApi';
import { CheckboxState, FileOperation } from './util/util'
import { RepoNode } from './dataObjects/RepoNode';
import { DocumentNode } from './dataObjects/DocumentNode';
import { S3BrowseComponent } from './components/S3Browser';
import {
    Button,
    Classes,
    Dialog,
    DialogBody,
    DialogFooter,
    Label,
} from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { jwtDecode } from 'jwt-decode';

enum CurrentUserIsAdmin {
    Yes,
    No,
    Unknown
}

interface SearchResultsPage {
    searchResults: MdoApi.SearchNode[],
    searchText: string,
    pageNumber: number
}

function App() {
    const rootFolder: FolderNode = {
        id: "0",
        name: "Home",
        editDate: 0, // editDate only matters for folders shown in the main table.
    }
    const [folderPath, setFolderPath] = useState([rootFolder]);
    const [baseServerURL, setBaseServerURL] = useState("");
    const [sortOrder, setSortOrder] = useState("title_asc")
    const [childrenRefetchRequiredCounter, setChildrenRefetchRequiredCounter] = useState(0); // App.tsx increments this, triggering a re-render, when a child node is created or destroyed.
    const [checkedDocuments, setCheckedDocuments] = useState<Set<DocumentNode>>(new Set()); // the documents whose checkboxes are checked in the main table
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const [showDestinationDialog, setShowDestinationDialog] = useState(false);
    const [destinationDialogOperationIcon, setDestinationDialogOperationIcon] = useState(IconNames.Move); // the icon to show in the destination dialog
    const [destinationDialogTitle, setDestinationDialogTitle] = useState("Select Move Destination"); // the title to show in the destination dialog
    const [selectedDestinationPath, setSelectedDestinationPath] = useState<FolderNode[]>([]); // the path to the folder where the copy or move operation will be performed, as selected in the pop up tree
    const [copyMoveNodes, setCopyMoveNodes] = useState<RepoNode[]>([]); // the nodes to be copied or moved once a destination is selected
    const [pendingCopyMoveMergeAction, setPendingCopyMoveMergeAction] = useState<FileOperation>(FileOperation.None); // the action to be performed on the nodes to be copied or moved once a destination is selected
    const [selectAllCheckboxState, setSelectAllCheckboxState] = useState(CheckboxState.Unchecked);  // the state of the select all checkbox in the FolderToolbar
    const [serverCommunicationEstablished, setServerCommunicationEstablished] = useState(false); // true if the server has been contacted and the server name has been retrieved
    const [showS3BrowseDialog, setShowS3BrowseDialog] = useState(false);
    const [s3BrowseSourcePath, setS3BrowseSourcePath] = useState<string>();
    const [searchResultsPage, setSearchResultsPage] = useState<SearchResultsPage>({searchResults: [], searchText: "", pageNumber: 1}); // the search results returned by a search
    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(CurrentUserIsAdmin.Unknown);
    const [showScanningCredentialsDialog, setShowScanningCredentialsDialog] = useState(false);
    const [scanningCredentials, setScanningCredentials] = useState<MdoApi.ScanningCredential>();
    const [backgroundActivityInfo, setBackGroundActivityInfo] = useState<string>();
    const [userList, setUserList] = useState<string[]>([]);
    const [filesToCombine, setFilesToCombine] = useState<MdoApi.Node[][]>([]); // the nodes to be combined once a destination is selected
    const serverName = baseServerURL.slice(0, baseServerURL.indexOf('-'));
    let folder_name_input = useRef(null);
    let nodeIdBeingRenamed = useRef('');

    // console.log("App.tsx: render, early. baseServerURL = " + baseServerURL + ", serverCommunicationEstablished = " + serverCommunicationEstablished + ", currentUserIsAdmin = " + currentUserIsAdmin);

    // Check if the user was just forwared back from the login page. If so, move the tokens from the 
    //   URL to local storage and remove the hash from the URL which causes a reloading of the page:
    const hash = window.location.hash.substring(1);
    if (hash) {
        interface TokenContainingName {
            email: string;
        }
        const params = new URLSearchParams(hash);
        const idToken = params.get('id_token');
        
        if (idToken) {
            console.log("App.moveTokensFromURLToLocalStorage: moving idToken to local storage");
            const decodedIdToken = jwtDecode<TokenContainingName>(idToken);
            // console.log("App.moveTokensFromURLToLocalStorage: decodedIdToken = " + JSON.stringify(decodedIdToken));

            let username = decodedIdToken['email'];
            console.log("App.moveTokensFromURLToLocalStorage: username = " + username);
            window.localStorage.setItem('idToken', idToken);
            window.localStorage.setItem('username', username);
        }
        // we'd save the accessToken here, if we wanted it. We don't need it since we do access control ourselves.
        
        // remove the hash from the URL:
        let newURL = window.location.origin + window.location.pathname
        console.log("App.moveTokensFromURLToLocalStorage: Setting the URL to remove the hash and then returning an empty page to cause a reload. New URL = " + newURL);
        window.location.replace(newURL);

        // skip the rest of the render, since we're going to reload the page:
        return (<></>);
    }

    if (baseServerURL === "") {
        let newBaseServerURL = "";
        if (window.location.hostname == 'localhost') {
            newBaseServerURL = "jfw-api.gotomdodocx.com"; // good enough for now.
            // newBaseServerURL = "jmv-api.gotomdodocx.com"; // good enough for now.
        } else {
            newBaseServerURL = window.location.hostname.replace(".", "-api.");
        }
        setBaseServerURL(newBaseServerURL);

        console.log("App.tsx: render, early. baseServerURL set to \"" + newBaseServerURL + "\" based on the browser URL.");
        MdoApi.setBaseServerURL(newBaseServerURL);

        // if (window.location.hostname !== 'localhost') {
        //     // this was the first render of a browse to a deployed repo. Save the URL in state data:
        //     setBaseServerURL(newBaseServerURL);
        //     // console.log("App.tsx: baseServerURL not found in local state. Set to " + newBaseServerURL + " based on the browser URL.");
        // } else {
        //     // this was the first render of a browse to localhost. See if the server name is in local storage:
        //     // const savedServerName = localStorage.getItem("serverName");
        //     // if (savedServerName != null) {
        //     //     // console.log("App.tsx: Server name of " + savedServerName + " retrieved from localStorage.");
        //     //     newBaseServerURL = savedServerName + "-api.gotomdodocx.com";
        //     //     setBaseServerURL(newBaseServerURL);
        //     // } else {
        //         // console.log("App.tsx: No baseServerURL found in local state, derivable from the URL, or stored in localStorage.");
        //         setBaseServerURL("jfw-api.gotomdodocx.com"); // good enough for now.
        //         // setBaseServerURL("jmv-api.gotomdodocx.com"); // good enough for now.
        //     // }
        // }

        // console.log("App.tsx: render, middle. Just called setBaseServerUrl(). Returning now to let the next render happen when baseServerURL is set.");
        return (<></>);
    }

    // console.log("App.tsx: render, late. baseServerURL = " + baseServerURL);

    // const serverSelected = (serverName: string) => {
    //     // set the DOM variable, triggering a repaint:
    //     setBaseServerURL(serverName + '-api.gotomdodocx.com');
    //     // save the server name for the next time the application is run
    //     localStorage.setItem("serverName", serverName);
    // }

    const folderSelected = (folder: FolderNode) => {
        // a breadcrumb folder or a child folder was clicked:
        let folderIndex = folderPath.indexOf(folder);
        if (folderIndex >= 0) {
            // a folder in the breadcrumb was clicked:
            setFolderPath(folderPath.slice(0, folderIndex + 1))
        } else {
            // a child folder was clicked:
            setFolderPath(folderPath.concat(folder))
        }
        setSortOrder("title_asc");
    }

    const folderPathSelected = (newFolderPath: FolderNode[]) => {
        // a left hand nav folder was clicked:
        if (newFolderPath.length < 1) {
            console.error("App.folderPathSelected: Zero length folder paths are not allowed. setFolderPath request ignored.");
        } else {
            setFolderPath(newFolderPath);
            setSortOrder("title_asc");
        }
    }

    function createFolder(folderName: string) {
        MdoApi.createFolderOnServer(folderPath[folderPath.length - 1].id, folderName)
            .then((response) => {
                if (response.success) {
                    console.log("App.createFolder: Child folder " + folderName + " created.");
                } else {
                    alert(response.error);
                }
            })
            .catch((error) => {
                alert("Could not create folder " + folderName + ". " + error);
            })
            .finally(() => {
                childrenRefetchRequired();
            });
    }

    const setFileOperation = (operation: FileOperation) => {
        performOperationOnNodes(operation, Array.from(checkedDocuments));
    }

    const performOperationOnNode = (operation: FileOperation, node: MdoApi.Node): void => {
        let repoNode: RepoNode = {id: node.id, name: node.nodeName, editDate: 0};  // performOperationOnNodes doesn't use the editDate, so we can set it to 0
        performOperationOnNodes(operation, [repoNode]);
        for (let x = 0; x < filesToCombine.length; x++) {
            let fileSet = filesToCombine[x];
            for (let y = 0; y < fileSet.length; y++) {
                let file = fileSet[y];
                if (file.id === node.id) {
                    if (fileSet.length <= 2) {
                        filesToCombine.splice(x, 1);
                    } else {
                        fileSet.splice(y, 1);
                    }
                }
            }
        }
        setFilesToCombine(filesToCombine);
    }

    const performOperationOnNodes = (operation: FileOperation, nodes: RepoNode[]): void => {
        // perform the operation on the nodes, then repaint the main window:
        console.log("App.performOperationOnNodes: performOperationOnNodes called with operation " + operation + " (move = " + FileOperation.Move +
            ", copy = " + FileOperation.Copy + ", delete = " + FileOperation.Delete + ", rename = " + FileOperation.Rename +
            ") on nodes " + nodes.map((node) => node.name));
        setPendingCopyMoveMergeAction(operation);
        let nodeIds = nodes.map((node) => node.id)
        if (operation === FileOperation.Delete) {
            if(window.confirm("Are you sure you want to delete the selected documents?")){
                MdoApi.deleteNodes(nodeIds)
                    .then((response) => {
                        console.log("App.performOperationOnNodes: Deletion successful.");
                    })
                    .catch((error) => {
                        let msg = "Deleted " + error.deleted + " of " + error.total + " nodes. Error deleting node " + error.failedNodeName + ": " + error.error;
                        console.error("App.performOperationOnNodes: "+msg);
                        alert(msg);
                    })
                    .finally(() => {
                        childrenRefetchRequired();
                    });
            }
        } else if (operation === FileOperation.Move) {
            setCopyMoveNodes(nodes);
            setShowDestinationDialog(true);
            setDestinationDialogOperationIcon(IconNames.Move);
            setDestinationDialogTitle("Select Move Destination");
            setSelectedDestinationPath([]);
        } else if (operation === FileOperation.Copy) {
            setCopyMoveNodes(nodes);
            setShowDestinationDialog(true);
            setDestinationDialogOperationIcon(IconNames.Duplicate);
            setDestinationDialogTitle("Select Copy Destination");
            setSelectedDestinationPath([]);
        } else if (operation === FileOperation.Merge) {
            setCopyMoveNodes(nodes);
            setShowDestinationDialog(true);
            setDestinationDialogOperationIcon(IconNames.GreaterThan);
            setDestinationDialogTitle("Select Merge Destination");
            setSelectedDestinationPath([]);
        } else if (operation === FileOperation.Rename) {
            setShowRenameDialog(true);
            nodeIdBeingRenamed.current = nodeIds[0];
        }
    }

    const processCopyOrMove = () => {
        if (selectedDestinationPath.length === 0) {
            console.log("App.processCopyOrMove: No folder selected. Command ignored.");
            setShowDestinationDialog(false);
            return;
        }
        const destinationFolderId = selectedDestinationPath[selectedDestinationPath.length - 1].id;
        const currentFolderId = folderPath[folderPath.length - 1].id;
        if (currentFolderId === destinationFolderId) {
            window.alert("The destination folder must be different from the source folder.");
            // leave the destination dialog visible.
            return;
        }
        setShowDestinationDialog(false);
        if (copyMoveNodes != null) {
            if ((pendingCopyMoveMergeAction === FileOperation.Copy) || (pendingCopyMoveMergeAction === FileOperation.Move)) {
                MdoApi.copyOrMoveNodes(copyMoveNodes.map((node) => node.id), destinationFolderId, pendingCopyMoveMergeAction === FileOperation.Copy)
                    .then((response) => {
                        console.log("App.processCopyOrMove: Copy or move successful.");
                    })
                    .catch((error) => {
                        let msg = error.message;
                        if (!msg){
                            msg = "Processed " + error.nodesSuccessfullyProcessed + " of " + error.nodesInReqeust + " nodes. Error processing node " + error.failedNodeName + ": " + error.error;
                        }
                        console.error(msg);
                        console.error(error);
                        alert(msg);
                    })
                    .finally(() => {
                        childrenRefetchRequired();
                    })
            } else if (pendingCopyMoveMergeAction === FileOperation.Merge) {
                let nodeId = copyMoveNodes[0].id;
                MdoApi.mergeFolders(nodeId, destinationFolderId)
                    .then((response) => {
                        console.log("App.processCopyOrMove: Merge successful.");
                    })
                    .catch((error) => {
                        let msg = error.message;
                        if (!msg){
                            msg = "Processed " + error.nodesSuccessfullyProcessed + " of " + error.nodesInReqeust + " nodes. Error processing node " + error.failedNodeName + ": " + error.error;
                        }
                        console.error(msg);
                        console.error(error);
                        alert(msg);
                    })
                    .finally(() => {
                        childrenRefetchRequired();
                    })
            }
        }
    }

    const processS3Import = () => {
        setShowS3BrowseDialog(false);
        if (s3BrowseSourcePath === undefined) {
            let errMsg = "You have to select a path in s3 before you can request the import.";
            console.error(errMsg);
            alert(errMsg);
        } else {
            if (s3BrowseSourcePath.length === 0) {
                let errMsg = "You have to select a path in s3 before you can request the import.";
                console.error(errMsg);
                alert(errMsg);
                return;
            }
            MdoApi.importS3Documents(s3BrowseSourcePath)
                .then((response) => {
                    console.log("App.processS3Import: Import of "+s3BrowseSourcePath+" started.");
                })
                .catch((error) => {
                    let msg = "Processed " + error.nodesSuccessfullyProcessed + " of " + error.nodesInReqeust + " nodes. Error processing node " + error.failedNodeName + ": " + error.error;
                    console.error(msg);
                    console.error(error);
                    alert(msg);
                })
                .finally(() => {
                    childrenRefetchRequired();
                })
        }
    }


    const reanameTextFieldKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            renameNode();
        }
    }

    const renameNode = () => {
        setShowRenameDialog(false);
        const inputElement = folder_name_input.current as unknown as HTMLInputElement;
        if (inputElement != null) {
            console.log("App.renameNode: Renaming node " + nodeIdBeingRenamed.current + " to " + inputElement.value);
            MdoApi.renameNodeInRepo(nodeIdBeingRenamed.current, inputElement.value)
                .then(response => {
                    console.log("App.renameNode: Rename response: " + JSON.stringify(response));
                    if (response.success) {
                        console.log("App.renameNode: Rename succeeded.");
                    } else {
                        console.log("App.renameNode: Rename of " + nodeIdBeingRenamed.current + " to " + inputElement.value + " failed: " + response.error);
                        alert("Rename failed. Check to see if the name is already in use in this folder.");
                    }
                })
                .catch((error) => {
                    console.log("App.renameNode: Attempt to rename " + nodeIdBeingRenamed.current + " to " + inputElement.value + " failed with an http error: " + error);
                    alert("Rename failed. Check to see if the name is already in use in this folder.");
                })
                .finally(() => {
                    childrenRefetchRequired();
                })
        } else {
            console.log("App.renameNode: No value was entered for the new foldername. Rename operation ignored.")
        }
    }

    const setCheckedDocumentsWithReason = (checkedDocuments: Set<DocumentNode>, becauseOfCheckboxClickInMainWindow: boolean): void => {
        // console.log("App.setCheckedDocumentsWithReason: setCheckedDocumentsWithReason called with " + checkedDocuments.size + " checked documents. becauseOfCheckboxClickInMainWindow = " + becauseOfCheckboxClickInMainWindow);
        setCheckedDocuments(checkedDocuments);
        if (becauseOfCheckboxClickInMainWindow) {
            setSelectAllCheckboxState(CheckboxState.Indeterminate);
        }
    }

    const childrenRefetchRequired = (bumpNumber = 1): void => {
        console.log("App.childrenRefetchRequired: childrenRefetchRequired called. Incrementing childrenRefetchRequiredCounter from " + childrenRefetchRequiredCounter + " to " + (childrenRefetchRequiredCounter + bumpNumber));
        // trigger a main window refresh, showing the new document:
        setChildrenRefetchRequiredCounter(childrenRefetchRequiredCounter + bumpNumber);
        setSelectAllCheckboxState(CheckboxState.Unchecked);
    }

    const search = (searchText: string, pageNumber: number = 1): void => {
        console.log("App.search: search called with searchText = " + searchText);
        let path = "";
        for (let i = 1; i < folderPath.length; i++) {
            path += "/" + folderPath[i].name;
        }
        MdoApi.apiSsearch(searchText, path, pageNumber)
            .then((response) => {
                console.log("App.search: Search response: ")
                console.log(response)
                if (response.searchResults.length === 0) {
                    alert("No documents found matching the search criteria.");
                }else{
                    setSearchResultsPage({searchResults: [...searchResultsPage.searchResults, ...response.searchResults], searchText: searchText, pageNumber: pageNumber})
                }
            })
            .catch((error) => {
                console.error("Search failed: " + error);
                alert("Search request failed. Please try again.")
            })
    }

    const createScanningCredentials = (folderId: string): void => {
        console.log("App.createScanningCredentials: createScanningCredentials called with folderId = " + folderId);
        MdoApi.getSelfScanningCredentials(folderId)
            .then((response) => {
                console.log("App.createScanningCredentials: createScanningCredentials response: ")
                console.log(response)
                setScanningCredentials(response.scanningCredential)
                setShowScanningCredentialsDialog(true)
            })
            .catch((error) => {
                console.error("createScanningCredentials failed: " + error);
                alert("Create scanning credentials request failed. Please try again.")
            })
    }

    const localGetBackgroundActivityInfo = (): void => {
        console.log("App.localGetBackgroundActivityInfo: localGetBackgroundActivityInfo called.");
        MdoApi.getBackgroundActivityInfo()
            .then((response) => {
                console.log("App.localGetBackgroundActivityInfo: getBackgroundActivityInfo response: ")
                console.log(response)
                setBackGroundActivityInfo(response.status)
            })
            .catch((error) => {
                console.error("getBackgroundActivityInfo failed: " + error);
                alert("Get background activity request failed. Please try again.")
            })
    }

    const localReindexOpenSearchPathsForDescendants = (folderId: string): void => {
        console.log("App.reindexOpenSearchPathsForDescendants: reindexOpenSearchPathsForDescendants called with folderId = " + folderId);
        MdoApi.reindexOpenSearchPathsForDescendants(folderId)
            .then((response) => {
                console.log("App.reindexOpenSearchPathsForDescendants: reindexOpenSearchPathsForDescendants response: ")
                console.log(response)
                alert("Please allow up to ten minutes for the reindexing to complete.  Check status with 'Get Background Processing Info' in hamburger menu")
            })
            .catch((error) => {
                console.error("reindexOpenSearchPathsForDescendants failed: " + error);
                alert("Reindex open search paths request failed. Please try again.")
            })
    }

    const listUsers = (): void => {
        console.log("App.listUsers: listUsers called.");
        MdoApi.getUsers()
            .then((response) => {
                console.log("App.listUsers: getUsers response: ")
                console.log(response)
                setUserList(response)
            })
            .catch((error) => {
                console.error("getUsers failed: " + error);
                alert("Get users request failed. Please try again.")
            })
    }

    const createCognitoUserSheet = (): void => {
        console.log("App.createCognitoUserSheet: createCognitoUserSheet called.");
        MdoApi.createCognitoUserSheetApi()
            .then((response) => {
                console.log("App.createCognitoUserSheet: createCognitoUserSheet response: ")
                console.log(response)
            })
            .catch((error) => {
                console.error("createCognitoUserSheet failed: " + error);
                alert("Create cognito user sheet request failed. Please try again.")
            })
            .finally(() => {
                childrenRefetchRequired();
            })
    }

    const createBillingReport = (): void => {
        console.log("App.createBillingReport: createBillingReport called.");
        MdoApi.createBillingReportApi()
            .then((response) => {
                console.log("App.createBillingReport: createBillingReport response: ")
                console.log(response)
            })
            .catch((error) => {
                console.error("createBillingReport failed: " + error);
                alert("Create billing report request failed. Please try again.")
            })
            .finally(() => {
                childrenRefetchRequired();
            })
    }

    const localResetUser = (username: string): void => {
        console.log("App.resetUser: resetUser called.");
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to reset the password for user "+username+
                "? This will reset their password to Mdodocx1!") === true) {
                    MdoApi.resetUserHardcodedPassword(username)
                .then((response) => {
                    console.log("App.localResetUser: resetUser response: ")
                    console.log(response)
                })
                .catch((error) => {
                    console.error("getUsers failed: " + error);
                    alert("Get users request failed. Please try again.")
                })
        }
    }

    const showCombinerDialog = (): void => {
        console.log("App.showCombinerDialog: showCombinerDialog called.");
        MdoApi.getFilesToCombine(currentFolder.id)
            .then((response) => {
                console.log("App.showCombinerDialog: getFilesToCombine response: ")
                console.log(response)
                if (response.length === 0) {
                    alert("No documents found to combine.");
                } else {
                    setFilesToCombine(response)
                }
            })
            .catch((error) => {
                console.error("getFilesToCombine failed: " + error);
                alert("Get files to combine request failed. Please try again.")
            }
        )
    }

    const combineDocuments = (): void => {
        console.log("App.combineDocuments: combineDocuments called. currentFolder.id = " + currentFolder.id + " and files:");
        console.log(filesToCombine);
        MdoApi.combineFiles(currentFolder.id, filesToCombine)
            .then((response) => {
                console.log("App.combineDocuments: combineFiles response: ")
                console.log(response)
                if (response.success) {
                    console.log("App.combineDocuments: combineFiles succeeded.");
                    console.log("App.combineDocuments: combineFiles succeeded. !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! response = " + JSON.stringify(response));
                    childrenRefetchRequired();
                } else {
                    console.log("App.combineDocuments: combineFiles failed.");
                    alert("Combine failed.");
                }
            })
            .catch((error) => {
                console.log("App.combineDocuments: Attempt to combine files failed with an http error: " + error);
                alert("Combine failed with the following error: " + error);
            })
        setFilesToCombine([]);
    }

    const onDocumentChosen = (documentId: string, documentName: string): void => {
        MdoApi.getDocumentURL(documentId, documentName)
            .then(downloadURL => {
                let alink = document.createElement('a');
                alink.href = downloadURL;
                alink.click();
            })
            .catch(error => {
                alert("Download of file " + documentName + " failed. Please refresh the page and try again.");
            })
    }
    
    interface OverlayProps {
        show: boolean;
    }

    // surely, there must be a better way to do this. With the "show" variable, maybe not? (jfw, 20130727)
    const Overlay: React.FC<OverlayProps> = ({ show }) => {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 9999,
                    display: show ? 'block' : 'none',
                }}
            />
        );
    };

    if (currentUserIsAdmin === CurrentUserIsAdmin.Unknown) {
        // console.log("App.tsx: Checking to see if current user is admin with baseServerURL set to '"+baseServerURL+"'...")
        MdoApi.getCurrentUserIsAdmin()
            .then((response) => {
                if (response.isAdmin){
                    setCurrentUserIsAdmin(CurrentUserIsAdmin.Yes);
                    // console.log("App.tsx: Set current user to admin to Yes.")
                }else{
                    setCurrentUserIsAdmin(CurrentUserIsAdmin.No);
                    // console.log("App.tsx: Set current user to admin to No.")
                }
            })
            .catch((error) => {
                console.error("Error getting current user's admin status. Set it to No. Error: "+error);
                setCurrentUserIsAdmin(CurrentUserIsAdmin.No);
            })
    }


    // console.log("App.tsx: Rendering App. childrenRefetchRequiredCounter = " + childrenRefetchRequiredCounter);

    const currentFolder = folderPath[folderPath.length - 1];

    let displayPathElements = selectedDestinationPath.map((pathElement) => pathElement['name'])
    let displayPath = '/' + displayPathElements.join('/');

    if (serverCommunicationEstablished === false) {
        // we may or may not have an unexpired idToken in local storage. Try a simple command. If it fails, MdoApi will forward the user to the logon page.
        console.log("App.tsx: Calling MdoApi.noopToProveCommunications()...");
        MdoApi.noopToProveCommunications()
        .then((response) => {
            console.log("App.tsx: Server communication established.");
            setServerCommunicationEstablished(true);
        })
        .catch((error) => {
            console.error("App.tsx: Server communication not established. Error: " + error);
        })

        // return basically empty page content immediately. When the noop call above completes and runs the .then function, the 
        //   serverCommunicationEstablished state variable will be set to true which will cause the page to be re-rendered:
        return (
            <div className='App' style={{ display: serverCommunicationEstablished ? "block" : "none" }}>
                'retrieving data from server...'
            </div>)
    }
    
    return (
        <div className='App' style={{ display: serverCommunicationEstablished ? "block" : "none" }}>
            <Dialog title={"Rename node"}
                icon={IconNames.Edit}
                isOpen={showRenameDialog}
                onClose={() => setShowRenameDialog(false)}>
                <DialogBody>
                    <Label>
                        New Name:
                        <input ref={folder_name_input} id="folder-name-input" className={Classes.INPUT} placeholder="Enter new name here" autoFocus onKeyPress={reanameTextFieldKeyPressed} />
                    </Label>
                </DialogBody>
                <DialogFooter actions={
                    <>
                        <Button intent="primary" text="Save" onClick={renameNode} />
                        <Button intent="primary" text="Cancel" onClick={() => setShowRenameDialog(false)} />
                    </>
                } />
            </Dialog>

            <Dialog title="Background Activity"
                icon="waves"
                isOpen={backgroundActivityInfo !== undefined}
                onClose={() => {setBackGroundActivityInfo(undefined); console.log('cleared bkg activity info');}}>
                <DialogBody>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {backgroundActivityInfo}
                    </div>
                    <p/>
                    <Button onClick={() => {localGetBackgroundActivityInfo()}}>Refresh</Button>
                </DialogBody>
            </Dialog>

            <Dialog title="Click on the user to reset their password to Mdodocx1!"
                icon="people"
                isOpen={userList.length > 0}
                onClose={() => {setUserList([]); console.log('cleared user list');}}>
                <DialogBody>
                        {userList.map((username, index) => (
                            <>
                                <p/>
                                <Button 
                                    onClick={() => {
                                        localResetUser(username);
                                        // commenting this out to keep the dialog open for multiple user resets
                                        // setUserList([]);
                                    }}>
                                    {username}
                                </Button>
                            </>
                        ))}
                </DialogBody>
            </Dialog>

            <Dialog title="Scanning Credentials" 
                icon="cube-add"
                className="Scanning-credentials-dialog"
                isOpen={showScanningCredentialsDialog}
                onClose={() => setShowScanningCredentialsDialog(false)}>
                <DialogBody>
                    <b>Stack name:</b> {scanningCredentials?.stackName}
                    <br />
                    <b>Bucket:</b> {scanningCredentials?.bucket}
                    <br />
                    <b>Base folder:</b> {scanningCredentials?.baseFolder}
                    <br />
                    <br />
                    <b>Access Key ID:</b> {scanningCredentials?.accessKeyId}
                    <br />
                    <Button onClick={() => navigator.clipboard.writeText(scanningCredentials?.accessKeyId as string)} icon={IconNames.Clipboard}>Copy Access Key ID</Button>
                    <br />
                    <br />
                    <b>Secret Access Key:</b> {scanningCredentials?.secretAccessKey}
                    <br />
                    <Button onClick={() => navigator.clipboard.writeText(scanningCredentials?.secretAccessKey as string)} icon={IconNames.Clipboard}>Copy Secret Access Key</Button>
                    <br />
                    <br />
                    <b>Remote Path:</b> {"/" + scanningCredentials?.bucket + "/" + scanningCredentials?.baseFolder}
                    <br />
                    <Button onClick={() => navigator.clipboard.writeText("/" + scanningCredentials?.bucket + "/" + scanningCredentials?.baseFolder)} icon={IconNames.Clipboard}>Copy Remote Path</Button>
                </DialogBody>
            </Dialog>

            <Dialog title={destinationDialogTitle}
                icon={destinationDialogOperationIcon}
                isOpen={showDestinationDialog}
                onClose={() => setShowDestinationDialog(false)}>
                <DialogBody>
                    <LeftHandNavComponent 
                        rootFolder={rootFolder} 
                        onFolderPathSelected={(folderArray) => { setSelectedDestinationPath(folderArray) }} 
                        baseServerURL={baseServerURL} />
                    <div id="Destination-dialog-display-path">
                        {displayPath}
                    </div>
                </DialogBody>
                <DialogFooter actions={
                    <>
                        <Button intent="primary" text="Save" onClick={() => processCopyOrMove()} />
                        <Button intent="primary" text="Cancel" onClick={() => setShowDestinationDialog(false)} />
                    </>
                } />
            </Dialog>

            <Dialog title="S3 Folder to Import"
                isOpen={showS3BrowseDialog}
                onClose={() => setShowS3BrowseDialog(false)}>
                <DialogBody>
                    <S3BrowseComponent 
                        rootFolders={[
                            {id:"mdo-alfresco-export-mdo/", name:"mdo-alfresco-export-mdo/", editDate:0},
                            {id:"mdo-alfresco-export-mdo2/", name:"mdo-alfresco-export-mdo2/", editDate:0},
                            {id:"mdo-alfresco-export-mdo3/", name:"mdo-alfresco-export-mdo3/", editDate:0},
                            {id:"mdo-alfresco-export-mdo4/", name:"mdo-alfresco-export-mdo4/", editDate:0},
                            {id:"mdo-alfresco-export-mdo4-additional-temp/", name:"mdo-alfresco-export-mdo4-additional-temp/", editDate:0},
                            {id:"mdo-alfresco-export-mdo10/", name:"mdo-alfresco-export-mdo10/", editDate:0},
                            {id:"mdo-alfresco-export-garberauto/", name:"mdo-alfresco-export-garberauto/", editDate:0},
                            {id:"mdo-alfresco-export-dorschel/", name:"mdo-alfresco-export-dorschel/", editDate:0}]}
                        onFolderPathSelected={(folderPath) => { setS3BrowseSourcePath(folderPath) }} 
                        baseServerURL={baseServerURL} />
                    <div id="Destination-dialog-display-path">
                        {s3BrowseSourcePath}
                    </div>
                </DialogBody>
                <DialogFooter actions={
                    <>
                        <Button intent="primary" text="Save" onClick={() => processS3Import()} />
                        <Button intent="primary" text="Cancel" onClick={() => setShowS3BrowseDialog(false)} />
                    </>
                } />
            </Dialog>

            <Dialog title="Combine Documents"
                className="search-results-dialog"
                icon={IconNames.DataLineage}
                isOpen={filesToCombine.length > 0}
                onClose={() => setFilesToCombine([])}>
                <DialogBody>
                    <table className='bp5-html-table bp5-interactive search-results-table'>
                        <tbody>
                            {filesToCombine.map((fileSet, group_index) => (
                                <>
                                    {fileSet.map((file, index) => (
                                        <tr key={group_index+':'+index} className={group_index % 2 === 0 ? 'dark-background' : 'light-background'}>
                                            <td className='table-id-column'>
                                                <Button icon="document" className=" bp5-button bp5-minimal Area-button" onClick={() => onDocumentChosen(file.id, file.nodeName)}>
                                                    {file.nodeName}
                                                </Button>
                                            </td>
                                            <td>
                                                {file.size}
                                            </td>
                                            <td>
                                                {file.date.substring(0, 10)}
                                            </td>
                                            <td>
                                                <Button icon={IconNames.Edit} title="Rename Document" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => performOperationOnNode(FileOperation.Rename, file)} />
                                                <Button icon={IconNames.Move} title="Move Document" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => performOperationOnNode(FileOperation.Move, file)} />
                                                <Button icon={IconNames.Trash} title="Delete Document" className=" bp5-button bp5-minimal Area-button Edit-button" onClick={() => performOperationOnNode(FileOperation.Delete, file)} />
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ))}
                        </tbody>
                    </table>
                </DialogBody>
                <DialogFooter actions={
                    <>
                        <Button intent="primary" text="Combine Documents" onClick={() => combineDocuments()} />
                        <Button intent="primary" text="Cancel" onClick={() => setFilesToCombine([])} />
                    </>
                } />
            </Dialog>

            <Dialog title={"Search Results"}
                className="search-results-dialog"
                icon={IconNames.Search}
                isOpen={searchResultsPage.searchResults.length > 0}
                onClose={() => setSearchResultsPage({searchResults: [], searchText: "", pageNumber: 0})}>
                <DialogBody>
                    <table className='bp5-html-table bp5-html-table-striped bp5-interactive search-results-table'>
                        <tbody>
                            {searchResultsPage.searchResults.map(child => (
                                <tr key={child.id}>
                                    <td className='table-id-column'>
                                        <Button icon="document" className=" bp5-button bp5-minimal Area-button" onClick={() => onDocumentChosen(child.id, child.nodeName)}>
                                            {child.nodeName}
                                        </Button>
                                    </td>
                                    <td>
                                        {child.path}
                                    </td>
                                    <td>
                                        {child.size}
                                    </td>
                                    <td>
                                        {new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(child.date))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* Conditionally render "Load more...".  Note that if the number of results happens to be divisible by 50, this will always show.  To be fixed later. */}
                    { 
                        searchResultsPage.searchResults.length % 50 === 0 
                            && <div id="load-more-search-results" onClick={() => search(searchResultsPage.searchText, searchResultsPage.pageNumber+1)}>Load more...</div>
                    }
                </DialogBody>
            </Dialog>

            <div className="App-header">
                <img src="MyDigitalOffice Logo-01.png" className="App-logo" alt="MDO Logo" />
            </div>
            <div className="Search-area">
                <SearchBarComponent 
                    currentServer={serverName} 
                    currentFolder={currentFolder} 
                    serverName={serverName} 
                    baseServerURL={baseServerURL} 
                    setShowS3BrowseDialog={setShowS3BrowseDialog}
                    search={search}
                    currentUserIsAdmin={currentUserIsAdmin===CurrentUserIsAdmin.Yes}
                    createScanningCredentials={createScanningCredentials}
                    getBackgroundActivityInfo={localGetBackgroundActivityInfo} 
                    reindexPathsForDescendants={localReindexOpenSearchPathsForDescendants}
                    resetUser={localResetUser}
                    listUsers={listUsers}
                    createCognitoUserSheet={createCognitoUserSheet}
                    createBillingReport={createBillingReport}
                    showCombinerDialog={showCombinerDialog}/>
            </div>
            <div id="Main-body">
                <span id="Left-hand-nav">
                    <LeftHandNavComponent 
                        rootFolder={rootFolder} 
                        onFolderPathSelected={folderPathSelected} 
                        baseServerURL={baseServerURL} />
                </span>
                <span id="Main-window-and-bread-crumb">
                    <div id="Bread-crumb">
                        <BreadcrumbComponent path={folderPath} onFolderChosen={folderSelected} />
                    </div>
                    <div id="Folder-toolbar-component">
                        <FolderToolbarComponent
                            currentFolder={currentFolder}
                            baseServerURL={baseServerURL}
                            selectAllCheckboxState={selectAllCheckboxState}
                            sortOrder={sortOrder}
                            createFolder={createFolder}
                            childrenRefetchRequired={childrenRefetchRequired}
                            setSortOrder={setSortOrder}
                            setSelectAllCheckboxState={setSelectAllCheckboxState}
                            setFileOperation={setFileOperation}
                            currentUserIsAdmin={currentUserIsAdmin===CurrentUserIsAdmin.Yes}
                            childrenRefetchRequiredCounter={childrenRefetchRequiredCounter} />
                    </div>
                    <div id='Main-window'>
                        <MainWindowComponent
                            rootFolder={rootFolder}
                            parentFolder={currentFolder}
                            baseServerURL={baseServerURL}
                            sortOrder={sortOrder}
                            selectAllCheckboxState={selectAllCheckboxState}
                            setCheckedDocumentsWithReason={setCheckedDocumentsWithReason}
                            onFolderChosen={folderSelected}
                            performOperationOnNodes={performOperationOnNodes}
                            childrenRefetchRequiredCounter={childrenRefetchRequiredCounter}
                            onDocumentChosen={onDocumentChosen}
                            currentUserIsAdmin={currentUserIsAdmin===CurrentUserIsAdmin.Yes}
                        />
                    </div>
                </span>
            </div>
            <div>
                © My Digital Office, 2024
            </div>
        </div>
    );
}

export default App;