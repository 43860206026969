import { Children } from '../dataObjects/Children';
import { DocumentNode } from '../dataObjects/DocumentNode';
import { FolderNode } from '../dataObjects/FolderNode';
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';


export interface Node {
    nodeName: string;
    id: string;
    date: string;
    size: number;
}

interface GetChildrenResponse {
    childFolders: Node[];
    childDocuments?: Node[];
}

interface GetNumberOfChildDocumentsResponse {
    success: boolean;
    numberOfChildDocuments: number;
}

interface GetS3ChildFoldersResponse {
    success: boolean;
    childFolders: string[];
}

interface CreateNodeResponse {
    success: boolean,
    id?: string,
    error?: CreateNodeResponseError,
}

interface CreateNodeResponseError {
    type: string,
    message: string,
}

interface CopyMoveOrDeleteResponse {
    success: boolean,
    error: string,
    failedNodeName: string,
    nodesInReqeust: number,
    nodesSuccessfullyProcessed: number
}

interface SuccessResponse {
    success: boolean,
}

export interface SearchNode {
    date: string;
    id: string;
    nodeName: string;
    path: string;
    size: number;
}

interface SearchResponse {
    success: boolean,
    searchResults: SearchNode[]
}

export interface ScanningCredential {
    accessKeyId: string,
    secretAccessKey: string,
    bucket: string,
    baseFolder: string,
    stackName: string
}

interface ScanningCredentialsResponse {
    success: boolean,
    scanningCredential: ScanningCredential
}

interface CurrentUserIsAdminResponse {
    isAdmin: boolean
}

interface GetBackgroundActivityInfoResponse {
    status: string
}

interface ReindexOpenSearchPathsForDescendantsResponse {
    status: string
}

interface GetFilesToCombineResponse {
    success: boolean,
    files: Node[][]
}

let baseServerURL: string = '';
let axiosClient: AxiosInstance = Axios.create();
let nonRetryAxiosClient: AxiosInstance = Axios.create();
let nonApiAxiosClient: AxiosInstance = Axios.create();

export function setBaseServerURL(url: string) {
    baseServerURL = url;

    // create the non-api axios client for fetching cognitoClientInfo.json
    //
    // This needs to be done before the getRequestHeaders call since that function could call redirectUserToLogin which
    //  needs the nonApiAxiosClient to get the cognitoClientInfo.json file.
    nonApiAxiosClient = Axios.create({
        baseURL: window.location.origin  // localhost if we're on localhost, a stack otherwise
     });
    axiosRetry(nonApiAxiosClient, { 
        retries: 5, 
        retryDelay: axiosRetry.exponentialDelay 
    });

    let headers = getRequestHeaders();

    axiosClient = Axios.create({
        headers: headers,
        withCredentials: true,
        baseURL: "https://" + baseServerURL  // Always a stack. This is for api calls.
     });
    axiosRetry(axiosClient, { 
        retries: 5, 
        retryDelay: axiosRetry.exponentialDelay 
    });
    
    // create a non-retrying axios client for requests that are known to time out, like merging big folders. We don't want the message to be repeated 5 times.
    nonRetryAxiosClient = Axios.create({
        headers: headers,
        withCredentials: true,
        baseURL: "https://" + baseServerURL  // Always a stack. This is for api calls.
     });
    axiosRetry(nonRetryAxiosClient, { 
        retries: 0  // Just try once. Don't kick off another lambda on the server
    });

    console.log("MdoApi.setBaseServerURL: baseServerURL set to " + baseServerURL);
}

export const getRequestHeaders = () => {
    const idToken = window.localStorage.getItem('idToken') || '';
    console.log("MdoApi.getRequestHeaders: idToken read from localStorage = " + idToken.substring(0, 64) + "...");
    if (idToken == null || idToken === '') {
        console.info("MdoApi.getRequestHeaders: idToken is null. This means that the user is not authenticated. Redirecting to the login page.")
        redirectUserToLogin();
    }
    return {
        Authorization: 'Bearer ' + idToken,
    }
}

async function redirectUserToLogin(){
    if (baseServerURL === ''){
        console.error("MdoApi.processPossibleAuthError: baseServerURL is not set, so we can't redirect to the login page yet.")
        return;
    }
    const url = '/static/data/cognitoClientInfo.json';
    try {
        // get the cognitoClientInfo.json file from the s3 bucket (by using the non-API axios client) and read its contents:
        const response = await nonApiAxiosClient.get(url, { responseType: 'blob'});
        const blob = new Blob([response.data], { type: 'application/json' });
        const reader = new FileReader();
        console.log("MdoApi: Getting cognitoClientInfo.json from "+url+" using origin "+window.location.origin);
        reader.onload = function() {
            const data = JSON.parse(this.result as string);
            // build the login URL and redirect to it:
            const authDomainName = baseServerURL.replace("-api", "-auth");
            const redirect_uri = window.location.origin + window.location.pathname;
            // redirect_uri = redirect_uri.replace("http://", "https://");
            const loginURL = "https://"+authDomainName+"/login?response_type=token&client_id="+data.COGNITO_CLIENT_ID+"&redirect_uri="+
                redirect_uri; //&scope=offline_access to get refresh tokens, if we go to oauth2.0, which isn't trivial
            
            console.log("MdoApi.processPossibleAuthError: Redirecting to "+loginURL);
            window.location.href = loginURL;
        };
        reader.readAsText(blob);
    } catch (localError) {
        console.error("MdoApi.processPossibleAuthError: Call to "+url+" failed with the following error:");
        console.error(localError);
    }
}


async function processPossibleAuthError(error: any){
    // if (error.response == undefined || error.response.status === 401) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.log("MdoApi.processPossibleAuthError: Got a " + error.response.status + " error. This means that the user is not authenticated. Redirecting to the login page.")
        // get the cognito clients from s3, using the same baseURL as the loaded web page:
        redirectUserToLogin();
    } else {
        console.error("MdoApi.processPossibleAuthError: processPossibleAuthError called with the following error:")
        console.error(error)
    }
}

// { data } in the following argument list is a directive to allow direct acccess to the "data" element in the passed in 
// AxiosResponse that contains a GetChildrenResponse. data is an object of type GetChildrenResponse.
const processChildFolders = ({ data }: AxiosResponse<GetChildrenResponse>): FolderNode[] => {
    // console.log("MdoApi.processChildFolders: Doing the response conversion. Found " + data.childFolders.length +
    //     " child folder(s) and maybe the child documents. childDocuments = " + data.childDocuments);
    let result = data.childFolders.map(folder => ({
        id: folder.id,
        name: folder.nodeName,
        editDate: Date.parse(folder.date),
    }))
    // console.log(result)
    return result
}

const processAllChildren = ({ data }: AxiosResponse<GetChildrenResponse>): Children => {
    let childFolders = data.childFolders.map(child => ({
        id: child.id,
        name: child.nodeName,
        editDate: Date.parse(child.date)
    }))
    let childDocuments: DocumentNode[] = [];
    if (data.childDocuments !== undefined) {
        childDocuments = data.childDocuments.map(child => ({
            id: child.id,
            name: child.nodeName,
            editDate: Date.parse(child.date),
            size: child.size,
        }))
    }
    let result = {
        'childFolders': childFolders,
        'childDocuments': childDocuments
    }
    console.log("MdoApi.processAllChildren: Got the following children:")
    console.log(result)
    return result
}

export const getChildrenFromServer = (folderId: string, sortOrder: string): Promise<Children> => {
    // console.log("MdoApi.getChildrenFromServer: getChildren called for folder ID " + folderId + " with baseServerURL " + baseServerURL)
    const promise = new Promise<Children>((resolve, reject) => {
        const url = "/children?folderId=" + encodeURIComponent(folderId) + "&sortOrder=" + sortOrder;
        axiosClient.get<GetChildrenResponse>(url)
            .then(response => resolve((processAllChildren(response))))
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.getChildrenFromServer: Call to /children failed with the following error:");
                console.error(error);
                reject(error)
            });
    });
    // console.log("returning the promise that will return the children for folder ID "+folderId)
    return promise;
}

export const getNumberOfChildDocumentsFromServer = (folderId: string): Promise<GetNumberOfChildDocumentsResponse> => {
    console.log("MdoApi.getNumberOfChildDocumentsFromServer: GetNumberOfChildDocuments called for folder ID " + folderId + " with baseServerURL " + baseServerURL)
    const promise = new Promise<GetNumberOfChildDocumentsResponse>((resolve, reject) => {
        const url = "/numberOfChildDocuments?folderId=" + encodeURIComponent(folderId);
        axiosClient.get<GetNumberOfChildDocumentsResponse>(url)
            .then(response => resolve(response.data))
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.getNumberOfChildDocumentsFromServer: Call to /getNumberOfChildDocuments failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const getCurrentUserIsAdmin = (): Promise<CurrentUserIsAdminResponse> => {
    // console.log("MdoApi.getCurrentUserIsAdmin: currentUserIsAdmin called with baseServerURL " + baseServerURL)
    const promise = new Promise<CurrentUserIsAdminResponse>((resolve, reject) => {
        const url = "/currentUserIsAdmin";
        axiosClient.get<CurrentUserIsAdminResponse>(url)
            .then(response => resolve(response.data))
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.getCurrentUserIsAdmin: Call to /currentUserIsAdmin failed with the following error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const getChildrenFoldersFromServer = (folderId: string): Promise<FolderNode[]> => {
    // console.log("MdoApi.getChildrenFoldersFromServer: getChildFolders called for folder ID " + folderId + " with baseServerURL " + baseServerURL)
    const promise = new Promise<FolderNode[]>((resolve, reject) => {
        const url = "/childFolders?folderId=" + encodeURIComponent(folderId);
        axiosClient.get<GetChildrenResponse>(url)
            .then(response => resolve((processChildFolders(response))))
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.getChildrenFoldersFromServer: Call to /childFolders failed with the following error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const getChildS3FoldersFromServer = (folder_id: string): Promise<string[]> => {
    console.log("MdoApi.getChildS3FoldersFromServer: getChildFolders called for folder " + folder_id + " with baseServerURL " + baseServerURL)
    const promise = new Promise<string[]>((resolve, reject) => {
        const url = "/browseS3Folders?s3Path=" + encodeURIComponent(folder_id);
        axiosClient.get<GetS3ChildFoldersResponse>(url)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.childFolders);
                } else {
                    console.error("MdoApi.getChildS3FoldersFromServer: Call to browseS3Folders failed:");
                    console.error(response);
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.getChildS3FoldersFromServer: Call to /browseS3Folders failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const createFolderOnServer = (parentFolderId: string, newFolderName: string): Promise<CreateNodeResponse> => {
    console.log("MdoApi.createFolderOnServer: createFolder called with new folder name " + newFolderName + " for parent folder ID " +
        parentFolderId + " with baseServerURL " + baseServerURL)
    const promise = new Promise<CreateNodeResponse>((resolve, reject) => {
        const url = "/folder";
        axiosClient.post<CreateNodeResponse>(url, JSON.stringify({ 'folderName': newFolderName, 'parentFolderId': parentFolderId }))
            .then(response => resolve(response.data))
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.createFolderOnServer: Call to create the folder '" + newFolderName + "' failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const createDocumentInRepo = (s3Key: string, fileName: string, size: number, lastModifiedTimestamp: number, folderId: string): Promise<CreateNodeResponse> => {
    console.log("MdoApi.createDocumentInRepo: createDocument called with name " + fileName + " for parent folder ID " +
        folderId + " with baseServerURL " + baseServerURL)
    const promise = new Promise<CreateNodeResponse>((resolve, reject) => {
        const url = '/document'
        const data = {
            'documentName': fileName,
            'size': size,
            'lastModifiedTimestamp': lastModifiedTimestamp,
            's3_key': s3Key,
            'folderId': folderId
        }
        axiosClient.post(url, JSON.stringify(data))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    console.log("MdoApi.createDocumentInRepo: Call to create the document '" + fileName + "' failed with the following error: ")
                    console.log(response.data.error);
                    reject(response.data.error);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.createDocumentInRepo: Call to create the document '" + fileName + "' failed with the following http error: ");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

interface GetDownloadURLResponse {
    downloadUrl: string;
}

export const getDocumentURL = (documentId: string, documentName: string): Promise<string> => {
    const promise = new Promise<string>((resolve, reject) => {
        const url = "/documentDownloadURL?documentId=" + encodeURIComponent(documentId);
        axiosClient.get<GetDownloadURLResponse>(url)
            .then(response => resolve(response.data.downloadUrl))
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.getDocumentURL: Call to get the download URL for document'" + documentName + "' (" + documentId + ") failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const deleteNodes = (nodeIds: string[]): Promise<CopyMoveOrDeleteResponse> => {
    const promise = new Promise<CopyMoveOrDeleteResponse>((resolve, reject) => {
        const url = "/deleteNodes";
        const data = { 'nodeIds': nodeIds }
        axiosClient.post<CopyMoveOrDeleteResponse>(url, JSON.stringify(data))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.deleteNodes: Call to delete the nodes '" + nodeIds + "' failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const renameNodeInRepo = (node_id: string, new_name: string): Promise<CreateNodeResponse> => {
    const promise = new Promise<CreateNodeResponse>((resolve, reject) => {
        const url = '/rename'
        const data = {
            'nodeId': node_id,
            'newName': new_name
        }
        console.log("MdoApi.renameNodeInRepo: Calling rename at " + url + " with " + JSON.stringify(data));
        axiosClient.post(url, JSON.stringify(data))
            .then(response => resolve(response.data))
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.renameNodeInRepo: Call to rename the node '" + node_id + "' to '" + new_name + "' failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const copyOrMoveNodes = (node_ids: string[], destination_folder_id: string, copy: boolean): Promise<CopyMoveOrDeleteResponse> => {
    const promise = new Promise<CopyMoveOrDeleteResponse>((resolve, reject) => {
        const url = '/copyOrMove'
        const data = {
            'nodeIds': node_ids,
            'destinationFolderId': destination_folder_id,
            'copy': copy
        }
        console.log("MdoApi.copyOrMoveNodes: Calling copy or move at " + url + " with " + JSON.stringify(data));
        axiosClient.post(url, JSON.stringify(data))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.copyOrMoveNodes: Call to copy or move the nodes '" + node_ids + "' to '" + destination_folder_id + "' failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const mergeFolders = (source_folder_id: string, destination_folder_id: string): Promise<CopyMoveOrDeleteResponse> => {
    const promise = new Promise<CopyMoveOrDeleteResponse>((resolve, reject) => {
        const url = '/mergeFolders'
        const data = {
            'sourceFolderId': source_folder_id,
            'destinationFolderId': destination_folder_id
        }
        console.log("MdoApi.mergeFolders: Calling mergemergeFoldersNodes at " + url + " with " + JSON.stringify(data));
        nonRetryAxiosClient.post(url, JSON.stringify(data))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.mergeFolders: Call to merge the files in '" + source_folder_id + "' to '" + destination_folder_id + "' failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const importS3Documents = (s3Path: string): Promise<SuccessResponse> => {
    const promise = new Promise<SuccessResponse>((resolve, reject) => {
        const url = '/importS3Documents'
        const data = {
            's3Path': s3Path
        }
        console.log("MdoApi.importS3Documents: Calling s3 importer at " + url + " with " + JSON.stringify(data));
        axiosClient.post(url, JSON.stringify(data))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.importS3Documents: Call to import s3 folder with '"+s3Path+"' failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const apiSsearch = (searchText: string, folderPath: string, pageNumber: number, pageSize: number = 50): Promise<SearchResponse> => {
    const promise = new Promise<SearchResponse>((resolve, reject) => {
        const url = '/search'
        const data = {
            'searchText': searchText,
            'folderPath': folderPath,
            'pageNumber': pageNumber,
            'pageSize': pageSize
        }
        console.log("MdoApi.apiSsearch: Calling search at " + url + " with " + JSON.stringify(data));
        axiosClient.post(url, JSON.stringify(data))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.apiSsearch: Call to search with '"+searchText+"' failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const getUsers = (): Promise<string[]> => {
// export const getUsers = (baseFolder=""): Promise<string[]> => {
    console.log("MdoApi.getUsers: getUsers called with baseServerURL " + baseServerURL)
    const promise = new Promise<string[]>((resolve, reject) => {
        let url = '/usernames'
        // if (baseFolder !== "") {
        //     url+='?baseFolder=' + encodeURIComponent(baseFolder)
        // }
        console.log("MdoApi.getUsers: Calling get user at " + url);
        axiosClient.get(url)
            .then(response => {
                console.log("MdoApi.getUsers: Got the following users:");
                console.log(response.data);
                let users = response.data["users"];
                resolve(users);
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.getUsers: Call to get user failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const createCognitoUserSheetApi = (): Promise<SuccessResponse> => {
    console.log("MdoApi.createCognitoUserSheet: createCognitoUserSheet called with baseServerURL " + baseServerURL)
    const promise = new Promise<SuccessResponse>((resolve, reject) => {
        let url = '/cognitoUsers'
        axiosClient.post(url, null)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.createCognitoUserSheet: Call to createCognitoUserSheet failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const createBillingReportApi = (): Promise<SuccessResponse> => {
    console.log("MdoApi.createBillingReportApi: createBillingReportApi called with baseServerURL " + baseServerURL)
    const promise = new Promise<SuccessResponse>((resolve, reject) => {
        let url = '/createBillingReport'
        axiosClient.post(url, null)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.createBillingReportApi: Call to createBillingReportApi failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const resetUserHardcodedPassword = (email: string): Promise<SuccessResponse> => {
    const promise = new Promise<SuccessResponse >((resolve, reject) => {
        let url = '/resetUserHardcodedPassword' 
        console.log("MdoApi.resetUserHardcodedPassword: Calling reset user at " + url);
        axiosClient.post(url, JSON.stringify({ 'email': email }))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.resetUser: Call to reset user failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const getFilesToCombine = (folderId: string): Promise<Node[][]> => {
    console.log("MdoApi.getFilesToCombine: getFilesToCombine called for folder ID " + folderId + " with baseServerURL " + baseServerURL)
    const promise = new Promise<Node[][]>((resolve, reject) => {
        const url = "/filesToCombine?folderId=" + encodeURIComponent(folderId);
        axiosClient.get<GetFilesToCombineResponse>(url)
            .then(response => {
                if (response.data.success === false) {
                    console.error("MdoApi.getFilesToCombine: Call to /filesToCombine failed:");
                    console.error(response);
                    reject(response.data);
                }
                resolve(response.data.files)
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.getFilesToCombine: Call to /filesToCombine failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const combineFiles = (folderId: string, fileSetsToCombine: Node[][]): Promise<SuccessResponse> => {
    const promise = new Promise<SuccessResponse>((resolve, reject) => {
        const url = '/combineFiles'
        const data = {
            'folderId': folderId,
            'fileSetsToCombine': fileSetsToCombine
        }
        console.log("MdoApi.combineFiles: Calling combineFiles at " + url + " with " + JSON.stringify(data));
        axiosClient.post(url, JSON.stringify(data), { timeout: 300000 })
            .then(response => {
                console.log("MdoApi.combineFiles: Got the following response:");
                console.log(response.data);
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.combineFiles: Call to combineFiles failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const getSelfScanningCredentials = (folderId: string): Promise<ScanningCredentialsResponse> => {
    const promise = new Promise<ScanningCredentialsResponse>((resolve, reject) => {
        let url = '/scanningCredentials?folderId=' + encodeURIComponent(folderId)

        console.log("MdoApi.getSelfScanningCredentials: Calling scanning credentials at " + url);
        axiosClient.post<ScanningCredentialsResponse>(url, JSON.stringify({ 'folderId': folderId }))
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                processPossibleAuthError(error);
                console.error("MdoApi.getSelfScanningCredentials: Call to scanning credentials failed with the following http error:");
                console.error(error);
                reject(error)
            });
    });
    return promise;
}

export const getBackgroundActivityInfo = (): Promise<GetBackgroundActivityInfoResponse> => {
    const promise = new Promise<GetBackgroundActivityInfoResponse>((resolve, reject) => {
        let url = '/getBackgroundActivity'

        console.log("MdoApi.getBackgroundActivityInfo: Calling background activity at " + url);
        axiosClient.get<GetBackgroundActivityInfoResponse>(url)
            .then(response => resolve(response.data))
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.getBackgroundActivityInfo: Call to get the level of background activity failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

export const reindexOpenSearchPathsForDescendants = (folderId: string): Promise<ReindexOpenSearchPathsForDescendantsResponse> => {
    const promise = new Promise<ReindexOpenSearchPathsForDescendantsResponse>((resolve, reject) => {
        let url = '/reindexOpenSearchPathsForDescendants?folderId=' + encodeURIComponent(folderId);

        console.log("MdoApi.reindexOpenSearchPathsVariableForDescendants: Calling reindex paths for descendants at " + url);
        axiosClient.post<ReindexOpenSearchPathsForDescendantsResponse>(url)
            .then(response => resolve(response.data))
            .catch(error => {
                processPossibleAuthError(error);
                console.log("MdoApi.reindexOpenSearchPathsVariableForDescendants: Call to reindex paths failed with the following error:");
                console.log(error);
                reject(error)
            });
    });
    return promise;
}

// export const setHttpOnlyCookies = (): Promise<ReindexOpenSearchPathsForDescendantsResponse> => {
//     const promise = new Promise<ReindexOpenSearchPathsForDescendantsResponse>((resolve, reject) => {
//         let url = '/setHttpOnlyCookies';

//         console.log("MdoApi.setHttpOnlyCookies: Calling reindex paths for descendants at " + url);
//         axiosClient.get<ReindexOpenSearchPathsForDescendantsResponse>(url)
//             .then(response => resolve(response.data))
//             .catch(error => {
//                 processPossibleAuthError(error);
//                 console.log("MdoApi.reindexOpenSearchPathsVariableForDescendants: Call to reindex paths failed with the following error:");
//                 console.log(error);
//                 reject(error)
//             });
//     });
//     return promise;
// }

export const noopToProveCommunications = (): Promise<SuccessResponse> => {
    const promise = new Promise<SuccessResponse>((resolve, reject) => {
        let url = '/noopToProveCommunications';

        console.log("MdoApi.noopToProveCommunications: Calling noop at " + url);
        // try this communication once, without retries, to see if the user is logged on. Don't 
        // delay forwarding the user to the logon page for retry operations.
        nonRetryAxiosClient.get<SuccessResponse>(url)
            .then(response => {
                console.log("MdoApi.noopToProveCommunications: Got the following response:");
                console.log(response);
                resolve(response.data);
            })
            .catch(error => {
                console.log("MdoApi.noopToProveCommunications: Call to noop failed with the following error:");
                console.log(error);
                processPossibleAuthError(error);
                reject(error)
            });
    });
    return promise;
}
