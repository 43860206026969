import Axios, { AxiosResponse } from 'axios';

import React, { useRef } from 'react';
import { Button } from "@blueprintjs/core";
import { FolderNode } from '../dataObjects/FolderNode';
import * as MdoApi from '../workers/MdoApi';
import { IconNames } from '@blueprintjs/icons';

interface Props {
    currentFolder: FolderNode;
    baseServerURL: string;
    childrenRefetchRequired: (bumpNumber?: number) => void;
}

interface GetDocumentUploadURLResponse {
    uploadUrl: {
        url: string,
        s3_key: string
    };
}

export function FileUpload(props: Props) {
    // numFilesUploadedRef is used as the optional bumpNumber parameter to childrenRefetchRequired. The user is potentially
    // creating multiple files at once, so we need to cause refetching of the children to as the documents are created in the
    // system but, more importantly, to cause the UI to update when the last document is created. All of the pending promises
    // for these creations have the same initial value for childrenRefetchRequiredCounter, so they'd all increment that number
    // by 1, resulting in the same final number, and no repaints, without this functionality.
    const numFilesUploadedRef = useRef(0);

    const visibleButtonPressed = () => {
        const doc = document;
        if (doc != null) {
            const input = doc.getElementById("fileInput");
            if (input != null) {
                console.log("Programmatically clicked the input button.")
                input.click();
            }
        }
    }

    const createDocument = (file: File, s3_key: string) => {
        MdoApi.createDocumentInRepo(s3_key, file.name, file.size, file.lastModified, props.currentFolder.id)
            .then(createNodeResponse => {
                numFilesUploadedRef.current++;
                console.log("Document " + file.name + " successfully created in the repository. It's document #" + numFilesUploadedRef.current + ".");
            })
            .catch(error => {
                console.log("May have failed to create document " + file.name + " in the repository.");
                console.log("error.type: " + error.type + ", error.message: " + error.message);
                alert(error.message);
                if (error.type === "UserParsingError") {
                    // UserParsingError means the file was in fact uploaded, so we need to increment the counter:
                    numFilesUploadedRef.current++;
                }
            })
            .finally(() => {
                props.childrenRefetchRequired(numFilesUploadedRef.current);
            })
    }

    const uploadFileToS3 = (file: File, { data }: AxiosResponse<GetDocumentUploadURLResponse>) => {
        const uploadUrl = data.uploadUrl;
        console.log("Uploading " + file.name + " to " + uploadUrl.url + ". The s3 key will be " + uploadUrl.s3_key);
        Axios.put(uploadUrl.url, file)
            .then(response => {
                console.log("File " + file.name + " stored in S3. Now, creating the file in the repo...");
                createDocument(file, uploadUrl.s3_key);
            })
            .catch(error => {
                console.log("Failed to upload the file " + file.name + " to the repo.");
                console.log(error);
                alert("Failed to upload the file " + file.name + " to the repo")
            })
    }

    // On file select (from the pop up)
    const onUploadPressed = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {  // don't leave this as an any
        console.log("Uploading file(s)")
        const files = (e.target as HTMLInputElement).files;
        if (files == null) {
            console.log("No files selected.")
        } else {
            console.log("Uploading " + files.length + " file(s)")
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                console.log("Gettting an s3 upload URL for " + file.name)
                // This should move to MdoApi (at a minimum, so we don't have to import getRequestHeaders, but, more
                // generally, that's where it belongs), but time is short, and that may mean another level of promises.
                // Later...
                const url = "https://" + props.baseServerURL + '/documentUploadURL?documentName=' + encodeURIComponent(file.name) + 
                            '&folderId=' + encodeURIComponent(props.currentFolder.id);
                Axios.get<GetDocumentUploadURLResponse>(url, { headers: MdoApi.getRequestHeaders() })
                    .then(response => {
                        console.log("Uploading file " + file.name + ":")
                        console.log(file)
                        uploadFileToS3(file, response)
                    })
                    .catch(error => {
                        console.log("Failed to upload file " + file.name)
                        console.log(error);
                        alert("Failed to upload file " + file.name)
                    })
            }
        }
        // make subsequent selections of the same file still fire the event:
        (e.target as HTMLInputElement).value = '';
    }


    return (
        <div>
            <input
                id='fileInput'
                type="file"
                onChange={onUploadPressed}
                multiple
                style={{ display: 'none' }}
            />
            <Button icon={IconNames.CloudUpload} id='Add-files-button' className="bp5-button Folder-toolbar-button" onClick={visibleButtonPressed}>Add Documents</Button>
        </div>
    );
}